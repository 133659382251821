import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import "../components/ProductoDetalle/ProductoDetalle.css";
import Productos_db from '../database/productos_db.json';

const ProductoDetalle = () => {
  const [categoriaData, setCategoriaData] = useState(null);
  const { categoria } = useParams();

  useEffect(() => {
    if (categoria) {
      const categoriaEncontrada = Productos_db.find(
        (categoriaItem) => categoriaItem.nombre.toLowerCase() === categoria.toLowerCase()
      );

      if (categoriaEncontrada) {
        setCategoriaData(categoriaEncontrada);
      } else {
        console.log("Categoría no encontrada");
      }
    }
  }, [categoria]);

  if (!categoriaData) {
    return <p>Cargando...</p>;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>• {categoriaData.meta_title}</title>
        <meta name="description" content={categoriaData.meta_description || "Descripción de la categoría"} />
        <meta name="theme-color" content="#CB3234" />
        <meta property="og:title" content={`Catálogo ${categoriaData.nombre} - Microled Argentina 🔴`} />
      </Helmet>

      {categoriaData.franja && (

        <div className="pdfranja" style={{
            backgroundImage: `url('${categoriaData.franja.url[0]}')`,
            backgroundPosition: categoriaData["background-position"] || "center",
          }}
        >
          <h2 className='pdh2'>{categoriaData.franja.titulo}</h2>
        </div>
     )}

      {categoriaData.productos.filter((producto) => producto.disponible).map((producto) => (
        <div key={producto.id} className="product" uk-height-match>

          <div className="photos uk-position-relative uk-visible-toggle uk-light"
            uk-slideshow="animation: push; autoplay: true; autoplay-interval: 3000"
            tabIndex="-1" uk-height-viewport="expand: true" >

            <div className="uk-slideshow-items ul_slider uk-height-1-1">
              {producto.photos.map((photo) => (
                <div key={photo?.url || Math.random()}>
                  <img
                    className="img-photo"
                    src={photo?.url || "/Assets/placeholder.jpg"}
                    alt={photo?.alt || "Imagen del producto"}
                    title={photo?.title || "Imagen del producto"}
                  />
                </div>
              ))}
       </div>

          </div>

          <div className="details">
            <div className="decorationline"></div>
            <h2>{producto.nombre}</h2>

            {producto.descripción && <p className="infoProducto">{producto.descripción}</p>}

            {producto.dimensiones_y_gabinete && (
              <p className="infoProducto">
                <img src="/Assets/icons/dimensiones.svg" alt="Pantallas Led Tamaño Microled" title='Icono dimensiones'/>
                <b className="bold">Dimensiones del gabinete: </b>
                <span>{producto.dimensiones_y_gabinete}</span>
              </p>
            )}

            {producto.led_y_color && (
              <p className="infoProducto">
                <img src="/Assets/icons/led.svg" alt="Led Microled" title='Icono Led'/>
                <b className="bold">Color y Tipo de Led: </b>
                <span>{producto.led_y_color}</span>
              </p>
            )}

            {producto.garantia && (
              <p className="infoProducto">
                <img src="/Assets/icons/garantia.svg" alt="Garantía Microled" title='Icono Garantía'/>
                <b className="bold">Garantía: </b> {producto.garantia}
              </p>
            )}

            {producto.programacion && (
              <p className="infoProducto">
                <img src="/Assets/icons/modificar.svg" alt="Pantallas Led programables Microled" title='Icono modificar'/>
                <b className="bold">Programación Modificable: </b>
                <span>{producto.programacion}</span>
              </p>
            )}

            {producto.cant_lineas && (
              <p className="infoProducto">
                <img src="/Assets/icons/lineas.svg" alt="Letreros muchas líneas Microled" title='Icono lineas'/>
                <b className="bold">Cantidad de Líneas: </b>
                <span>{producto.cant_lineas}</span>
              </p>
            )}

            {producto.infoDigitos && (
              <p className="infoProducto">
                <img src="/Assets/icons/altura_digitos.svg" alt="Altura Pantalla Led Microled" title='Icono altura_digitos'/>
                <span className="bold">Altura de Dígitos y Caracteres Visibles: </span>
                <span>{producto.infoDigitos}</span>
              </p>
            )}

            {producto.sonido && (
              <p className="infoProducto">
                <img src="/Assets/icons/sonido.svg" alt="Pantallas led con sonido Microled" title='Icono sonido'/>
                <b className="bold">Sonido: </b>
                <span>{producto.sonido}</span>
              </p>
            )}

            {producto.alimentación && (
              <p className="infoProducto">
                <img src="/Assets/icons/info.svg" alt="Información Pantallas Led Microled" title='Icono info'/>
                <b className="bold">Otros Detalles: </b>
                <span>El letrero funciona con una alimentación de {producto.alimentación}v.</span>
              </p>
            )}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default ProductoDetalle;
