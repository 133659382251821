import React from 'react';
import { Helmet } from 'react-helmet';  // Importar Helmet
import "../components/Contacto/Contacto.css";
import Comunicate from "../components/Comunicate/Comunicate";

function Contacto() {
  return (
    <React.Fragment>
      <section>
        <Helmet>
          <title>• Contacto | Hablanos por WhatsApp - Microled 🔴</title>
          <meta
            name="description"
            content="Comunicate con nosotros y hace tu consulta. Estamos por C.A.B.A. - info@microled.com.ar - (011) 4952-3703."
          />

          {/* Open Graph */}
          <meta
            property="og:title"
            content="Contacto con Microled Argentina 🔴 - Pantallas Led"
          />
          <meta
            property="og:image"
            content="%PUBLIC_URL%/Assets/Microled-Mati-Calvier.svg"
          />
          <meta name="theme-color" content="#CB3234" />
        </Helmet>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.7012601675724!2d-58.40108148477011!3d-34.611714980457485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccae8a2d1a1b9%3A0x7f4f6da5b67bc8bf!2sCM%26M%20Microled%20F%C3%A1brica%20de%20Letreros%20Electr%C3%B3nicos%20Led!5e0!3m2!1ses-419!2sar!4v1601332920934!5m2!1ses-419!2sar"
          width="100%"
          height="320em"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
          aria-hidden="false"
          tabIndex="0"
          title="Ubicación de Microled en el mapa"  // Título único añadido
        ></iframe>

        <h1>Pantallas Led Para Eventos</h1>

        <Comunicate />
      </section>
    </React.Fragment>
  );
}

export default Contacto;
