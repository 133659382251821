import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';  
import "../components/Productos/Productos.css";
import Productos_db from '../database/productos_db.json';
import { Link } from 'react-router-dom';  // Asegúrate de importar Link desde react-router-dom

const Productos = () => {
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    setProductos(Productos_db); // Cargar productos en el estado
  }, []);

  return (
    <>
      <section>
        <Helmet>
          <title>• Catálogo Pantallas Led y Cartelería Microled 🔴</title>
          <meta name="description" content="Conocé nuestros diferentes tipos de Pantallas Led y Cartelería Electrónica. Tenemos Cruces para farmacias, Pantallas led Estacionamientos, etc." />
          <meta name="theme-color" content="#CB3234" />
          {/* Open Graph */}
          <meta property="og:title" content="Catálogo Pantallas Led Microled Argentina 🔴" />
        </Helmet>
       
        <div className="pfranja" style={{
            backgroundImage: `url('/img/franjas/franja-productos.jpg')`,
            backgroundPosition: "background-position center",
          }}>
          <h1 className='ph1'>CATEGORÍAS DE PRODUCTOS</h1>
        </div>

        <div className="alinearDiv">
          <div className="productos">
            {productos.length > 0 ? (
              productos.filter(element => element.disponible).map((element) => (
                <Link 
                  key={element.id}
                  className="eachproduct uk-card uk-card-default uk-card-hover" 
                  to={`${element.nombre}`}
                >
                  <img 
                    id="foto-producto-1" 
                    src={element.portada.url[0]} 
                    alt={element.portada.alt[0]} 
                    title={element.portada.titulo} 
                  />
                  <div className='preP'>
                  <p>{element.portada.titulo}</p>
                  </div>
                </Link> 
              ))
            ) : (
              <p>Cargando productos...</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Productos;
