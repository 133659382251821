import React from "react";
import "./Comunicate.css";

// Subcomponente para mostrar un enlace con icono y texto
const ContactItem = ({ link, icon, alt, title, description }) => {
  return (
    <a target="_blank" rel="nofollow noreferrer" href={link}>
      <div className="image">
        <img src={icon} alt={alt} />
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </a>
  );
};

// Componente principal para la sección de Comunicate
const Comunicate = () => {
  return (
    <div 
      className="comunicate" 
      style={{
        backgroundImage: `url('/img/franjas/led-contact-img.jpg')`, // Asegúrate de que la ruta sea correcta
      }}
    >

      <section className="contacto">
        <div className="columnacontacto">
          <ContactItem
            link="https://wa.me/5491136112732"
            icon="/Assets/icons/Whatsapp Form.svg"
            alt="Microled WhatsApp"
            title="Whatsapp"
            description="+54 9 11 3611-2732"
          />
          <ContactItem
            link="tel:4952-3703"
            icon="/Assets/icons/Telefono Form.svg"
            alt="Microled Telefono"
            title="TELÉFONO"
            description="(011) 4952-3703"
          />
          <ContactItem
            link="mailto:info@microled.com.ar"
            icon="/Assets/icons/Mail Form.svg"
            alt="Microled Mail"
            title="E-MAIL"
            description="info@microled.com.ar"
          />
          <ContactItem
            link="https://g.page/CMYM_Microled_Argentina"
            icon="/Assets/icons/Ubicacion Form.svg"
            alt="Microled Ubicacion"
            title="Ubicación"
            description="Pichincha 188, C.A.B.A."
          />
        </div>
      </section>
      
    </div>
  );
};

export default Comunicate;
