import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer>
            <div className="footerlinetwo">
                <h4>Nosotros</h4>
                <section className="networks">
                    <SocialLink href="https://wa.me/5491136112732" imgSrc="/Assets/icons/whatsapp.svg" alt="Icono-Whatsapp" title="Icono WhatsApp"/>
                    <SocialLink href="https://www.instagram.com/microled_argentina" imgSrc="/Assets/icons/Instagram.svg" alt="Icono-Instagram" title="Icono Instagram"/>
                    <SocialLink href="https://www.facebook.com/microled.argentina/" imgSrc="/Assets/icons/Facebook.svg" alt="Icono-Facebook" title="Icono Facebook"/>
                    <SocialLink href="tel:4952-3703" imgSrc="/Assets/icons/telefono.svg" alt="Icono-Telefono" title="Icono Telefono"/>
                    <SocialLink href="https://g.page/CMYM_Microled_Argentina" imgSrc="/Assets/icons/Ubicación.svg" alt="Icono-Ubicación" title="Icono Ubicación"/>
                </section>
            </div>

            <div className="divisor"></div>

            <section className="footerlinethree">
                <p>
                    <a href="tel:4952-3703">4952-3703</a>
                    <span> | </span> 
                    <a rel="nofollow" href="mailto:info@microled.com.ar">info@microled.com.ar</a>
                    <span> | </span> 
                    <a rel="nofollow" href="https://wa.me/5491136112732">+54 9 11 3611-2732</a>
                </p>
                <p>
                    <a rel="nofollow" href="https://g.page/CMYM_Microled_Argentina">
                        Pichincha 188 (C1082) Ciudad Autónoma de Buenos Aires
                    </a>
                </p>
            </section>
            <p className="copyrigth">© Copyright 2025 Cia de Manufactura y Mediciones SRL</p>
        </footer>
    );
}

function SocialLink({ href, imgSrc, alt }) {
    return (
        <a target="_blank" rel="nofollow noreferrer" href={href}>
            <img className="eachnetwork" src={imgSrc} alt={alt} />
        </a>
    );
}

export default Footer;
