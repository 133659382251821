import React, { useEffect } from "react";
import { NavLink } from "react-router-dom"; // Importa NavLink
import "./Header.css";

function Header() {
  useEffect(() => {

  }, []);
  
  return (
    <header>
      <div
        uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar"
      >
        <nav className="uk-navbar-container" uk-navbar="dropbar: true;">
          <NavLink className="aimgnav" to="/">
            <img src="/Assets/Microled-Mati-Calvier.svg" alt="Logo Microled" title="Logo Microled" />
          </NavLink>

          {/* Menú normal para pantallas grandes */}
          <ul className="links">
            <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? "link link-active" : "link")}>
                Inicio
              </NavLink>
            </li>

            <li>
              <NavLink to="/clientes" className={({ isActive }) => (isActive ? "link link-active" : "link")} >
                Clientes
              </NavLink>
            </li>

            <li>
              <NavLink to="/productos" className={({ isActive }) => (isActive ? "link link-active" : "link")} >
                Productos
              </NavLink>
            </li>

            <li>
              <NavLink to="/contacto" className={({ isActive }) => (isActive ? "link link-active" : "link")}  >
                Contacto
              </NavLink>
            </li>
          </ul>

           
        </nav>
      </div>
      <a href="https://wa.me/1133425093" target="_blank" rel="noopener noreferrer" className="whatsapp-icon">
        <img src="/Assets/icons/Whatsapp Form.svg" alt="Logo WhatsApp" title="Logo WhatsApp"/>
      </a>
    </header>
  );
}

export default Header;
