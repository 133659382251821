import React from 'react'
import { Helmet } from 'react-helmet';  // Importar Helmet
import "../components/Error/Error.css";

function Contacto () {

 return (
   
    <section>
          {/* Agregar metadatos con Helmet */}
          <Helmet>
             <title>Página no encontrada - Microled Argentina 🔴</title>
             <meta name="description" content="Mira nuestros trabajos realizados y empresas que trabajaron con nosotros." />
             <meta name="robots" content="noindex, nofollow" />
   
             {/* Open Graph */}
             <meta property="og:image" content="%PUBLIC_URL%/Assets/Microled-Mati-Calvier.svg" />
             <meta property="og:type" content="website" />
   
             <meta name="theme-color" content="#CB3234" />
           </Helmet>
       
           <div className="error-container">
                <div className="error-content">
                <h1 className="error-title">¡Oops!</h1>
                <p className="error-message">Algo salió mal. No encontramos la página que buscás.</p>
                <a href="/" className="error-link">Volver al inicio</a>
      </div>
    </div>

</section>
 )
}

export default Contacto;