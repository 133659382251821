import React from 'react';
import { Helmet } from 'react-helmet';  // Importar Helmet
import "../components/Clientes/Clientes.css";

function Clientes() {
  const trabajosRealizados = [
    "bolsa_de_comercio.jpg",
    "Contador de bicicletas (1).jpg",
    "Dot-Estacionamiento 4.jpg",
    "marcha-federal-plaza-de-mayo-36.jpg",
    "MICROLED - ANDENES LINEA H (1).jpg",
    "peajes autopistas del sol.jpg",
    "TARIDADOR DE PRECIOS YPF.JPG"
  ];

  const logosClientes = [
    "Aeropuertos Argentina 2000.png",
    "Alto Palermo.png",
    "Axion.png",
    "Banca Francés.png",
    "Banca Provincia.png",
    "Bolsa De Comerio.png",
    "Coca Cola.png",
    "Wolswagen.png",
    "Dot.png",
    "Fiat.png",
    "HSBC.png",
    "La Rural.png",
    "Metrovias.png",
    "Patio Bullrich.png",
    "Puma.png",
    "Renault.png",
    "Santander.png",
    "Ternium.png",
    "Mesa de trabajo 32.png",
    "Mesa de trabajo 36.png",
    "Mesa de trabajo 37.png"
  ];

  return (
    <section>
       {/* Agregar metadatos con Helmet */}
       <Helmet>
          <title>• Clientes y Trabajos Realizados - Microled 🔴</title>
          <meta name="description" content="Mira nuestros trabajos realizados y empresas que trabajaron con nosotros." />

          {/* Open Graph */}
          <meta property="og:title" content="Trabajos realizados con Microled Argentina 🔴" />
          <meta property="og:image" content="%PUBLIC_URL%/Assets/Microled-Mati-Calvier.svg" />
          <meta property="og:url" content="http://www.microled.com.ar/clientes" />
          <meta property="og:type" content="website" />

          <meta name="theme-color" content="#CB3234" />
        </Helmet>
        
    
      <div className="cfranja" style={{
            backgroundImage: `url('/img/franjas/franja_formulario.jpg')`,
          }}>
          <h2 className='ch1'>Confiaron en nosotros</h2>
        </div>

      <h3 className='ch3'>Trabajos Realizados</h3>

      <div className="fotosrealizados" uk-slider="autoplay: true, autoplay-interval: 0.1, center: true" uk-grid uk-lightbox="animation: slide">
        <div className="uk-slider-container uk-cover-container">
          <ul className="uk-slider-items uk-width-1-1 uk-child-width-1-4  uk-child-width-1-3@s uk-child-width-1-5@l" uk-grid>
            {trabajosRealizados.map((imagen, index) => (
              <li key={index}>
                <a href={`/img/clientes/realizados_clientes/${imagen}`} data-caption="">
                  <img src={`/img/clientes/realizados_clientes/${imagen}`} alt={`Trabajo Realizado ${index + 1}`} loading="lazy" title={`Trabajo Realizado ${imagen + 1}`} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="clientes">
        <h3 className='ch3-2'>Confiaron en Nosotros</h3>
        <div className="logosmarcas">
          {logosClientes.map((logo, index) => (
            <img key={index} src={`/img/clientes/logos/${logo}`} alt={`Cliente ${index + 1}`} loading="lazy" title={`Cliente ${logo + 1}`} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Clientes;