import React, { Component } from 'react';
import { Helmet } from 'react-helmet';  // Importar Helmet
import "../components/Index/Index.css";
import Comunicate from "../components/Comunicate/Comunicate";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
          {/* Agregar los metadatos con Helmet */}
          <Helmet>
            <title>▷ Pantallas Led de Video - Microled Argentina 🔴</title>
            <meta name="description" content="Comprá o Alquilá Pantallas led. Tenemos Pantallas Led Dolar, Cruces Led para farmacias, Carteles de estacionamiento, Pantallas para Colectivos, etc." />
            <meta name="theme-color" content="#CB3234" />
            
            {/* Open Graph */}
            <meta property="og:title" content="Pantallas Led Microled Argentina 🔴" />
            <meta property="og:image" content="%PUBLIC_URL%/img/home/tapate_la_boca_linea_h.jpg" />
          </Helmet>

        <h1>Pantalla Led</h1>

        <section>

        <div className="Ifranja" style={{
            backgroundImage: `url('/img/franjas/franja_index.jpg')`,
          }}>
          <h2 className='ih2'>Señalización por Pantallas Led</h2>
        </div>

          {/* CATEGORÍAS PRINCIPALES */}
          <div className="trabajoslinea">
            <a className="cardsProduct" href="/productos/colectivos">
              <div className="trabajosuno">
                <div className="realizadosfotos">
                  <img
                    src="/img/home/First_3/Linea_71_Frante.jpg"
                    alt="Microled Pantalla Led Colectivos"
                    title='Microled Pantalla Led Colectivos'
                  />
                </div>
                <h3>CARTELERÍA PARA COLECTIVOS</h3>
                <p>
                  Ramaleros para colectivos Full Color. Simple y Doble línea
                  resistente a vibraciones. Programables por Wifi y Cable.
                </p>
                <button className="vermas">Ver Más</button>
              </div>
            </a>

            <a className="cardsProduct" href="/productos/mercado_de_valores">
              <div className="trabajosuno">
                <div className="realizadosfotos">
                  <img
                    src="/img/productos/Mercado_de_Valores/Cambio_Led/Cambio_Led_1.jpg"
                    alt="Microled Pantalla Led Dolar"
                    title='Microled Pantalla Led Dolar'
                  />
                </div>
                <h3>PANTALLAS LED PARA CASAS DE CAMBIO</h3>
                <p>
                  Pantallas y Letreros Led para mostrar las cotizaciones del
                  dólar compra - venta o de los instrumentos financieros que se
                  requieran como Bitcoin.
                </p>
                <button className="vermas">Ver Más</button>
              </div>
            </a>

            <a className="cardsProduct" href="/productos/turneros">
              <div className="trabajosuno">
                <div className="realizadosfotos">
                  <img
                    src="/img/productos/Turno_Puesto/Turno/U300_MICROLED/U300_MICROLED_1.jpg"
                    alt="Microled Turnero"
                    title='alt="Microled Turnero"'
                  />
                </div>
                <h3>TURNEROS LED CON TURNO Y PUESTO</h3>
                <p>
                  Turneros Led y llamadores ideales para organizar personas de
                  manera ordenada. Turno - Puesto para operar con múltiples filas.
                </p>
                <button className="vermas">Ver Más</button>
              </div>
            </a>
          </div>

          <div className="realizados" uk-grid uk-lightbox="animation: slide">
            <h3>Trabajos Realizados</h3>

            <div
              id="filafotos"
              className="uk-child-width-1-3 uk-grid uk-text-center"
              uk-grid
            >
              <div className="each-fotos">
                <a href="/img/home/bocaDeSubte.jpg" data-caption="Estación de Subte">
                  <img
                    id="foto4"
                    src="/img/home/bocaDeSubte.jpg"
                    alt="Estación de Subte"
                    title="Estación de Subte"
                  />
                </a>
              </div>

              <div className="each-fotos">
                <a href="/img/home/Santander.jpg" data-caption="Banco Santander Rio">
                  <img
                    id="foto5"
                    src="/img/home/Santander.jpg"
                    alt="Banco Santander Rio"
                    title="Banco Santander Rio"
                  />
                </a>
              </div>

              <div className="each-fotos">
                <a href="/img/home/peajes autopistas del sol.jpg" data-caption="Peaje Autopista Panamericana">
                  <img
                    id="foto6"
                    src="/img/home/peajes autopistas del sol.jpg"
                    alt="Peaje Autopista Panamericana"
                    title="Peaje Autopista Panamericana"
                  />
                </a>
              </div>

              <div className="each-fotos">
                <a href="/img/home/tapate_la_boca_linea_h.jpg" data-caption="Anden de subte Línea H">
                  <img
                    id="foto7"
                    src="/img/home/tapate_la_boca_linea_h.jpg"
                    alt="Anden de subte Línea H"
                    title="Anden de subte Línea H"
                  />
                </a>
              </div>

              <div className="each-fotos">
                <a href="/img/home/Dot-Estacionamiento 4.jpg" data-caption="Sistema de Estacionamiento Contable en el Shopping Dot">
                  <img
                    id="foto8"
                    src="/img/home/Dot-Estacionamiento 4.jpg"
                    alt="Sistema de Estacionamiento Contable en el Shopping Dot"
                    title="Sistema de Estacionamiento Contable en el Shopping Dot"
                  />
                </a>
              </div>

              <div className="each-fotos">
                <a href="/img/home/camion_reciclaje.jpg" data-caption="Pantalla Led para Publicidad sobre Reciclado">
                  <img
                    id="foto9"
                    src="/img/home/camion_reciclaje.jpg"
                    alt="Pantalla Led para Publicidad sobre Reciclado"
                    title="Pantalla Led para Publicidad sobre Reciclado"
                  />
                </a>
              </div>

              <div className="each-fotos">
                <a href="/img/home/Alto Palermo.jpg" data-caption="Estacionamiento Alto Palermo">
                  <img
                    id="foto1"
                    src="/img/home/Alto Palermo.jpg"
                    alt="Estacionamiento Alto Palermo"
                    title="Estacionamiento Alto Palermo"
                  />
                </a>
              </div>

              <div className="each-fotos">
                <a href="/img/home/bolsa_de_comercio.jpg" data-caption="Bolsa de Comercio de Buenos Aires">
                  <img
                    id="foto2"
                    src="/img/home/bolsa_de_comercio.jpg"
                    alt="Bolsa de Comercio de Buenos Aires"
                    title="Bolsa de Comercio de Buenos Aires"
                  />
                </a>
              </div>

              <div className="each-fotos">
                <a href="/img/home/tecnopolis.jpeg" data-caption="Contador de Casos de Covid para Tecnopolis">
                  <img
                    id="foto3"
                    src="/img/home/tecnopolis.jpeg"
                    alt="Contador de Casos de Covid para Tecnopolis"
                    title="Contador de Casos de Covid para Tecnopolis"
                  />
                </a>
              </div>
            </div>
          </div>
          {/* FIN Trabajos Realizados */}

          <Comunicate />
        </section>
      </React.Fragment>
    );
  }
}

export default Index;
