import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import Index from "./screens/Index";
import Productos from "./screens/Productos";
import Clientes from "./screens/Clientes";
import Contacto from "./screens/Contacto";
import ProductoDetalle from "./screens/ProductoDetalle";
import ErrorPage from "./screens/Error";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valor: 0,
    };
  }

  saludar() {
    alert("¡Bienvenida/o al sitio!");
  }

  render() {
    return (
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="/productos" element={<Productos />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/productos/:categoria" element={<ProductoDetalle /> } />
          <Route path="*" element={<ErrorPage />} /> {/* Ruta de error para cualquier cosa que no coincida */}
        </Routes>
        <Footer />
      </Router>
    );
  }
}

export default App;
